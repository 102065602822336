import * as fb from "../../firebase";
import router from "../../router";
import store from "../index";


export default {
  state: {
    invitedUserProfile: {},
    userProfile: {},
    userId: null,
    token: null,
    isSuperAdmin: null,
    isAdmin: null,
    isClientAdmin: null
  },
  getters: {
    userProfile: state => state.userProfile,
    userId: state => state.userId,
    isAdmin: state => state.isAdmin,
    isSuperAdmin: state => state.isSuperAdmin,
    isClientAdmin: state => state.isClientAdmin
  },
  mutations: {
    setUserProfile(state, data) {
      state.userProfile = data;
    },
    setUserId(state, uid) {
      state.userId = uid;
    },
    setToken(state, token) {
      state.token = token
    },
    setIsAdmin(state, status) {
      state.isAdmin = status
    },
    setIsSuperAdmin(state, status) {
      state.isSuperAdmin = status
    },
    setIsClientAdmin(state, status) {
      state.isClientAdmin = status
    }
  },
  actions: {
    login({ dispatch, commit }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.signInWithEmailAndPassword(form.email, form.password).then(cred => {

          // Fetching User Profile on successful login


          dispatch('fetchUserProfile', cred.user);
          resolve('Successfully Logged In');
        })
          .catch(error => {
            reject(error.message)
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    logout({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('setUserUnavailable');
        fb.auth.signOut()
          .then(() => {
            commit('setUserProfile', {});
            commit('setUserId', null);
            router.push('/login');
            resolve('Successfully Logged Out')
            location.reload();
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    createAdminAccount({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .then(cred => {
            //create user profile in usercollection
            fb.registeredUsersCollection.doc(cred.user.uid).set({
              name: form.name,
              email: form.email,
              userRole: form.userRole,
              createdOn: new Date(),
              onlineStatus: 'Unavailable',
              groups: [],
              activeSessions: []
            }).then(() => {
              dispatch('initiateAppData')
                .then(() => {
                  dispatch('createDefaultRoles')
                  resolve('Account Created Successfully.');
                  location.reload();
                })
            })
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    fetchUserProfile({ commit, dispatch }, user) {
      if (router.currentRoute.path !== '/') {
        router.push('/')
      }

      //fetch user profile data
      fb.registeredUsersCollection.doc(user.uid)
        .onSnapshot(userData => {
          //set userProfile in state
          commit('setUserProfile', userData);
          //set userId in state
          commit('setUserId', user.uid);

          if (userData.data() === undefined) {
            //dispatch('logout')
          } else {
            if (userData.data().userRole === 'Super Admin') {
              commit('setIsSuperAdmin', true)
            } else if (userData.data().userRole === 'Admin') {
              commit('setIsAdmin', true)
            }  else {
              if (userData.data().telepresence === undefined || userData.data().telepresence === null) {
                commit('setShowAlert', true)
              } else if (userData.data().telepresence === false) {
                commit('setShowAlert', false)
              } else {
                commit('setShowAlert', false)
              }
            }

            dispatch('loadData', userData.data().userRole)
          }

          if (router.currentRoute.path === '/login' || router.currentRoute.path === '/admin') {
            router.push('/')
          }
        });
    },
    getInvitedUserInformation({ commit }, uId) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(uId).get()
          .then(userData => {
            commit('setInvitedUserProfile', userData.data());
            resolve('Fetched User Information');
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    createAccount({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .then(cred => {
            //create user profile in usercollection
            fb.registeredUsersCollection.doc(cred.user.uid).set({
              name: form.name,
              email: form.email,
              userRole: form.userRole,
              groups: [],
              createdOn: new Date(),
              telepresence: null,
              onlineStatus: 'Unavailable'
            }).then(() => {
              fb.invitedUsersCollection.doc(form.invitationId).delete()
                .then(() => {
                  fb.auth.signOut();
                  resolve('Account Created Successfully. Login to Continue');
                  //router.push('/login');
                })
            })
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    checkInitialization({ state }) {
      return new Promise((resolve, reject) => {
        fb.appDataCollection.get('config')
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              resolve(true)
            } else {
              resolve(false)
            }
          })
      })
    },
    setUserAvailable({ state }) {
      fb.registeredUsersCollection.doc(state.userId).update({
        onlineStatus: 'Available',
      })
    },
    setUserBusy({ state }) {
      fb.registeredUsersCollection.doc(state.userId).update({
        onlineStatus: 'Busy',
      })
    },
    setUserUnavailable({ state }) {
      fb.registeredUsersCollection.doc(state.userId).update({
        onlineStatus: 'Unavailable',
      })
    },
    setUserOffCall({ state }) {
      fb.registeredUsersCollection.doc(state.userId).update({
        onlineStatus: 'Available',
        onCall: false
      })
    },
    setUserOnCall({ state }) {
      fb.registeredUsersCollection.doc(state.userId).update({
        onlineStatus: 'Busy',
        onCall: true
      })
    },
    manageUserPresence({ getters, dispatch }, user) {

      // To maintain Session Storage at Browser level. Helps in Telepresence
      var myStorage = window.sessionStorage
      var userStatusDatabaseRef = fb.firebase.database().ref('/status/' + user.uid);

      if (getters.userProfile.data().onlineStatus === 'Busy') {
        var isOnlineForDatabase = {
          state: 'Busy',
          last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
        };
      } else {
        isOnlineForDatabase = {
          state: 'Available',
          last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
        };
      }

      fb.firebase.database().ref('.info/connected').on('value', function (snapshot) {
        // If we're not currently connected, don't do anything.

        if (snapshot.val() === false) return;

        userStatusDatabaseRef.push(isOnlineForDatabase).then(response => {
          myStorage.setItem('sessionID', response.key.toString());
          dispatch('handleIncomingRequest')
          //dispatch('handleAddtoExistingCallRequest')
          var userStatusDatabaseRefDel = fb.firebase.database().ref('/status/' + user.uid + '/' + response.key);
          userStatusDatabaseRefDel.onDisconnect().remove()
        })
      });
    },

  }
}
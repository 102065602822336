import * as fb from "../../firebase";
import moment from "moment";
import axios from "axios";
export default {
  state: {
    selectKioskAnalytics: "",
    selectedKiosk: "",
    selectedDuration: "Today",
    displayDate: "",
    todayBarChart: [
      { category: "8 AM", value: 0 },
      { category: "9 AM", value: 0 },
      { category: "10 AM", value: 0 },
      { category: "11 AM", value: 0 },
      { category: "12 PM", value: 0 },
      { category: "1 PM", value: 0 },
      { category: "2 PM", value: 0 },
      { category: "3 PM", value: 0 },
      { category: "4 PM", value: 0 },
      { category: "5 PM", value: 0 },
    ],
    pieChart: [
      { label: "Faqs", value: 0 },
      { label: "Maps", value: 0 },
      { label: "Forms", value: 0 },
      { label: "Payments", value: 0 },
      { label: "Hearings", value: 0 },
      { label: "QnA", value: 0 },
      { label: "Telepresence", value: 0 },
      { label: "E-Filing", value: 0 },
      { label: "Services", value: 0 },
    ],
    pieChartWF: [
      { label: "Faqs", value: 0 },
      { label: "Maps", value: 0 },
      { label: "Forms", value: 0 },
      { label: "Payments", value: 0 },
      { label: "Hearings", value: 0 },
      { label: "QnA", value: 0 },
      { label: "Jury Check In", value: 0 },
    ],
    pieChartFF: [
      { label: "Protection Order", value: 0 },
      { label: "Guide and File", value: 0 },
      { label: "File and Serve", value: 0 },
      { label: "Portal", value: 0 },
      { label: "QnA", value: 0 },
    ],
    pieChartPO: [
      { label: "Order of Protection", value: 0 },
      { label: "Civil No Contact Order", value: 0 },
      { label: "Stalking No Contact Order", value: 0 },
      { label: "QnA", value: 0 },
    ],
    pieChartEF: [
      { label: "Guide and File", value: 0 },
      { label: "File and Serve", value: 0 },
      { label: "Portal", value: 0 },
      { label: "QnA", value: 0 },
    ],
    languageBarChart: [
      { category: "English", value: 0 },
      { category: "Spanish", value: 0 },
    ],
    pieChartTotal: 0,
    pieChartTotalWF: 0,
    pieChartTotalFF: 0,
    pieChartTotalPO: 0,
    pieChartTotalEF: 0,
    singleDatePicker: false,
    selectedSingleDate: null,
    weekDatePicker: false,
    selectedWeekDate: null,
    fiscalYearPicker: false,
    enteredFiscalYear: "",
    closeWithoutSelection: 0,
    totalData: [],
  },
  getters: {
    selectKioskAnalytics: (state) => state.selectKioskAnalytics,
    getSelectedKiosk: (state) => state.selectedKiosk,
    todayBarChart: (state) => state.todayBarChart,
    pieChart: (state) => state.pieChart,
    getPieChartTotal: (state) => state.pieChartTotal,
    pieChartWF: (state) => state.pieChartWF,
    getPieChartWFTotal: (state) => state.pieChartTotalWF,
    pieChartFF: (state) => state.pieChartFF,
    getPieChartFFTotal: (state) => state.pieChartTotalFF,
    pieChartPO: (state) => state.pieChartPO,
    getPieChartPOTotal: (state) => state.pieChartTotalPO,
    pieChartEF: (state) => state.pieChartEF,
    getPieChartEFTotal: (state) => state.pieChartTotalEF,
    languageBarChart: (state) => state.languageBarChart,
    selectedDuration: (state) => state.selectedDuration,
    singleDatePicker: (state) => state.singleDatePicker,
    selectedSingleDate: (state) => state.selectedSingleDate,
    weekDatePicker: (state) => state.weekDatePicker,
    selectedWeekDate: (state) => state.selectedWeekDate,
    displayDate: (state) => state.displayDate,
    fiscalYearPicker: (state) => state.fiscalYearPicker,
    enteredFiscalYear: (state) => state.enteredFiscalYear,
    closeWithoutSelection: (state) => state.closeWithoutSelection,
    getTotalData: (state) => state.totalData,
  },
  mutations: {
    setSelectKioskAnalytics(state, kioskid) {
      state.selectKioskAnalytics = kioskid;
    },
    setSelectedKiosk(state, kiosk) {
      state.selectedKiosk = kiosk;
    },
    setSelectedDuration(state, duration) {
      state.selectedDuration = duration;
    },
    setPieChartTotal(state, total) {
      state.pieChartTotal = total;
    },
    setPieChartWFTotal(state, total) {
      state.pieChartTotalWF = total;
    },
    setPieChartFFTotal(state, total) {
      state.pieChartTotalFF = total;
    },
    setPieChartPOTotal(state, total) {
      state.pieChartTotalPO = total;
    },
    setPieChartEFTotal(state, total) {
      state.pieChartTotalEF = total;
    },
    setSingleDatePicker(state, status) {
      state.singleDatePicker = status;
    },
    setSelectedSingleDate(state, date) {
      state.selectedSingleDate = date;
    },
    setWeekDatePicker(state, status) {
      state.weekDatePicker = status;
    },
    setSelectedWeekDate(state, dates) {
      state.selectedWeekDate = dates;
    },
    setDisplayDate(state, dateString) {
      console.log("Date String: ", dateString);
      state.displayDate = dateString;
    },
    setFiscalYearPicker(state, status) {
      state.fiscalYearPicker = status;
    },
    setEnteredFiscalYear(state, year) {
      state.enteredFiscalYear = year;
    },
    setcloseWithoutSelection(state, value) {
      state.closeWithoutSelection = value;
    },
    setTotalData(state, totalDataList) {
      state.totalData.push(totalDataList);
    },
    clearTotalData(state){
      state.totalData = []
    },
    resetPieChart(state) {
      state.pieChart = [
        { label: "Faqs", value: 0 },
        { label: "Maps", value: 0 },
        { label: "Forms", value: 0 },
        { label: "Jury Check In", value: 0 },
        { label: "Hearings", value: 0 },
        { label: "QnA", value: 0 },
        { label: "Telepresence", value: 0 },
        { label: "E-Filing", value: 0 },
        { label: "Services", value: 0 },
      ];
    },
    resetPieChartWF(state) {
      state.pieChartWF = [
        { label: "Faqs", value: 0 },
        { label: "Maps", value: 0 },
        { label: "Forms", value: 0 },
        { label: "Payments", value: 0 },
        { label: "Hearings", value: 0 },
        { label: "QnA", value: 0 },
        { label: "Jury Check In", value: 0 },
        { label: "Telepresence", value: 0 },
      ];
    },
    resetPieChartFF(state) {
      state.pieChartFF = [
        { label: "Protection Order", value: 0 },
        { label: "Guide and File", value: 0 },
        { label: "File and Serve", value: 0 },
        { label: "Portal", value: 0 },
        { label: "QnA", value: 0 },
      ];
    },
    resetPieChartPO(state) {
      state.pieChartPO = [
        { label: "Order of Protection", value: 0 },
        { label: "Civil No Contact Order", value: 0 },
        { label: "Stalking No Contact Order", value: 0 },
        { label: "QnA", value: 0 },
      ];
    },
    resetPieChartEF(state) {
      state.pieChartEF = [
        { label: "Guide and File", value: 0 },
        { label: "File and Serve", value: 0 },
        { label: "Portal", value: 0 },
        { label: "QnA", value: 0 },
      ];
    },
    resetLanguageBarChart(state) {
      state.languageBarChart = [
        { category: "English", value: 0 },
        { category: "Spanish", value: 0 },
      ];
    },
    resetTodayBarChart(state) {
      state.todayBarChart = [
        { category: "8 AM", value: 0 },
        { category: "9 AM", value: 0 },
        { category: "10 AM", value: 0 },
        { category: "11 AM", value: 0 },
        { category: "12 PM", value: 0 },
        { category: "1 PM", value: 0 },
        { category: "2 PM", value: 0 },
        { category: "3 PM", value: 0 },
        { category: "4 PM", value: 0 },
        { category: "5 PM", value: 0 },
      ];
    },
  },
  actions: {
    getKioskAnalytics({ commit, getters, dispatch }, duration) {
      return new Promise((resolve, reject) => {
        let analyticsObj = {};
        let usersessionList = [];
        const today = new Date();
        let startDate, endDate;
        if (duration.type === "Today") {
          today.setHours(0, 0, 0, 0);
          startDate = today.toISOString();
          const today1 = new Date();
          const tomorrow = new Date(today1);
          tomorrow.setDate(today1.getDate() + 1);
          endDate = tomorrow.toISOString();
        } else if (duration.type === "Yesterday") {
          today.setHours(0, 0, 0, 0);
          endDate = today.toISOString();
          const tempDate = new Date();
          const yesterday = new Date(tempDate);
          yesterday.setDate(tempDate.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);
          startDate = yesterday.toISOString();
          // commit("setDisplayDate", moment(startDate).format("L"));
        } else if (duration.type === "Select Date") {
          startDate = getters.selectedSingleDate.startDate.toISOString();
          endDate = getters.selectedSingleDate.endDate.toISOString();
          // commit("setDisplayDate", moment(startDate).format("L"));
        } else if (duration.type === "This Week") {
          const weekToday = new Date();
          const currentDayOfWeek = weekToday.getDay();
          const startW = new Date(weekToday);
          startW.setDate(weekToday.getDate() - currentDayOfWeek);
          startDate = startW.toISOString();
          const endW = new Date(weekToday);
          endW.setDate(weekToday.getDate() + (6 - currentDayOfWeek));
          endDate = endW.toISOString();
        } else if (duration.type === "Select Week") {
          startDate = getters.selectedWeekDate.startDate.toISOString();
          endDate = getters.selectedWeekDate.endDate.toISOString();
          // commit(
          //   "setDisplayDate",
          //   moment(startDate).format("L") + " - " + moment(endDate).format("L")
          // );
        } else if (duration.type === "2024") {
          const annualToday = new Date();
          const oneYearAgo = new Date(annualToday);
          oneYearAgo.setFullYear(annualToday.getFullYear() - 1);
          startDate = oneYearAgo.toISOString();
          endDate = annualToday.toISOString();
        } else if (duration.type === "Fiscal Year") {
          startDate = getters.enteredFiscalYear.startDate.toISOString();
          endDate = getters.enteredFiscalYear.endDate.toISOString();
          // commit(
          //   "setDisplayDate",
          //   moment(startDate).format("L") + " - " + moment(endDate).format("L")
          // );
        } else {
          console.log("Custom Duration");
        }

        let config1 = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://strapi.arsconnect.com/user-sessions?browserKioskId=${getters.selectKioskAnalytics}&sessionStartTime_gte=${startDate}&sessionStartTime_lte=${endDate}&_limit=-1`,
          headers: {},
        };

        let config2 = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://cms.d8taos.arsconnect.com/qnas?browserKioskId=${getters.selectKioskAnalytics}&timeStamp_gte=${startDate}&timeStamp_lte=${endDate}&_limit=-1`,
          headers: {},
        };
        axios
          .request(config1)
          .then((response) => {
            usersessionList = response.data;
            analyticsObj = {
              userSessionAnalytics: usersessionList,
              totalUserSessions: usersessionList.length,
              overallPerformance: 0,
              totalQuestionsAsked: usersessionList.filter(
                (doc) => doc.sessionData.requestType === "Speech"
              ).length,
            };
            commit("setKioskAnalytics", analyticsObj);
            commit("resetTodayBarChart");
            commit("resetPieChart");
            commit("resetLanguageBarChart");
            if (usersessionList.length !== 0) {
              if (duration.type === "Today") {
                dispatch("createTodayBarChart", usersessionList);
              } else if (duration.type === "Yesterday") {
                dispatch("createTodayBarChart", usersessionList);
              } else if (duration.type === "Select Date") {
                dispatch("createTodayBarChart", usersessionList);
              }
              dispatch("createPieChart", usersessionList);
              dispatch("createPOPieChart", usersessionList);
              dispatch("createEFPieChart", usersessionList);
              //dispatch("createLanguageBarChart", usersessionList);
            } else {
              //
            }
            resolve("Kiosk analytical data fetched");
          })
          .catch((error) => {
            reject(error.message);
          });
        resolve("Kiosk analytical data fetched");
      });
    },
    createTodayBarChart({ getters, commit }, usersessionList) {
      usersessionList.forEach((session) => {
        let day = moment(session.sessionStartTime).format("H");
        switch (day) {
          case "8":
            var eight = getters.todayBarChart.find(
              (item) => item.category === "8 AM"
            );
            eight.value = eight.value + 1;
            break;
          case "9":
            var nine = getters.todayBarChart.find(
              (item) => item.category === "9 AM"
            );
            nine.value = nine.value + 1;
            break;
          case "10":
            var ten = getters.todayBarChart.find(
              (item) => item.category === "10 AM"
            );
            ten.value = ten.value + 1;
            break;
          case "11":
            var eleven = getters.todayBarChart.find(
              (item) => item.category === "11 AM"
            );
            eleven.value = eleven.value + 1;
            break;
          case "12":
            var twelve = getters.todayBarChart.find(
              (item) => item.category === "12 PM"
            );
            twelve.value = twelve.value + 1;
            break;
          case "13":
            var thirteen = getters.todayBarChart.find(
              (item) => item.category === "1 PM"
            );
            thirteen.value = thirteen.value + 1;
            break;
          case "14":
            var fourteen = getters.todayBarChart.find(
              (item) => item.category === "2 PM"
            );
            fourteen.value = fourteen.value + 1;
            break;
          case "15":
            var fifteen = getters.todayBarChart.find(
              (item) => item.category === "3 PM"
            );
            fifteen.value = fifteen.value + 1;
            break;
          case "16":
            var sixteen = getters.todayBarChart.find(
              (item) => item.category === "4 PM"
            );
            sixteen.value = sixteen.value + 1;
            break;
          case "17":
            var seventeen = getters.todayBarChart.find(
              (item) => item.category === "5 PM"
            );
            seventeen.value = seventeen.value + 1;
            break;
        }
      });
    },
    createPieChart({ getters, commit }, usersessionList) {
      commit("resetPieChart");
      usersessionList.forEach((session) => {
        const data = session.sessionData;
        var faq = getters.pieChart.find((item) => item.label === "Faqs");
        var form = getters.pieChart.find((item) => item.label === "Forms");
        var map = getters.pieChart.find((item) => item.label === "Maps");
        var hearing = getters.pieChart.find(
          (item) => item.label === "Hearings"
        );
        var jury = getters.pieChart.find(
          (item) => item.label === "Jury Check In"
        );
        var tele = getters.pieChart.find(
          (item) => item.label === "Telepresence"
        );
        var ai = getters.pieChart.find((item) => item.label === "QnA");
        var efile = getters.pieChart.find((item) => item.label === "E-Filing");
        var service = getters.pieChart.find(
          (item) => item.label === "Services"
        );

        if (data.response === "faqs") {
          faq.value = faq.value + 1;
        } else if (data.response === "forms") {
          form.value = form.value + 1;
        } else if (data.response === "maps") {
          map.value = map.value + 1;
        } else if (data.response === "todayshearing") {
          hearing.value = hearing.value + 1;
        } else if (data.response === "Jury Check In") {
          jury.value = jury.value + 1;
        } else if (data.response === "Telepresence") {
          tele.value = tele.value + 1;
        } else if (data.module === "QnA") {
          ai.value = ai.value + 1;
        } else if (data.response === "efiling") {
          efile.value = efile.value + 1;
        } else if (data.response === "onlineservices") {
          service.value = service.value + 1;
        }

        commit(
          "setPieChartTotal",
          faq.value +
            form.value +
            map.value +
            hearing.value +
            service.value +
            efile.value +
            tele.value
        );
      });
    },
    pullTotalInteractionData({ commit, getters, dispatch }) {
      commit("clearTotalData")
      const weekToday = new Date();
      const currentDayOfWeek = weekToday.getDay();
      const startW = new Date(weekToday);
      startW.setDate(weekToday.getDate() - currentDayOfWeek);
      let startDate = startW.toISOString();
      const endW = new Date(weekToday);
      endW.setDate(weekToday.getDate() + (6 - currentDayOfWeek));
      let endDate = endW.toISOString();

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://strapi.arsconnect.com/user-sessions?_limit=-1&sessionStartTime_gte=${startDate}&sessionStartTime_lte=${endDate}`,
        headers: {},
      };

      axios.request(config).then((response) => {
        //        console.log("All kiosk interaction Data: ", response.data);
        let kiosks = getters.allRegisteredKiosks.filter(
          (doc) => doc.data().show === "ecourts"
        );

        kiosks.forEach((doc) => {
          let data = {
            id: doc.data().arsBrowserId,
            category: doc.data().name,
            value: response.data.filter(
              (doc1) => doc1.browserKioskId === doc.data().arsBrowserId
            ).length,
          };
          commit("setTotalData", data);
          commit(
            "setDisplayDate",
            moment(startDate).format("L") + " - " + moment(endDate).format("L")
          );
          dispatch(
            "createWFPieChart",
            response.data.filter(
              (doc) =>
                doc.browserKioskId === "ARS-D-ARS" ||
                doc.browserKioskId === "ARS-D-ARS2"
            )
          );
          dispatch(
            "createFFPieChart",
            response.data.filter(
              (doc) =>
                doc.browserKioskId === "ARS-D-PROTECTION" ||
                doc.browserKioskId === "ARS-D-FORMS"
            )
          );
        });
      });
    },
    createWFPieChart({ getters, commit }, usersessionList) {
      commit("resetPieChartWF");
      usersessionList.forEach((session) => {
        const data = session.sessionData;
        var faq = getters.pieChartWF.find((item) => item.label === "Faqs");
        var form = getters.pieChartWF.find((item) => item.label === "Forms");
        var map = getters.pieChartWF.find((item) => item.label === "Maps");
        var hearing = getters.pieChartWF.find(
          (item) => item.label === "Hearings"
        );
        var jury = getters.pieChartWF.find(
          (item) => item.label === "Jury Check In"
        );
        var tele = getters.pieChartWF.find(
          (item) => item.label === "Telepresence"
        );
        var ai = getters.pieChartWF.find((item) => item.label === "QnA");

        if (data.response === "faqs") {
          faq.value = faq.value + 1;
        } else if (data.response === "forms") {
          form.value = form.value + 1;
        } else if (data.response === "maps") {
          map.value = map.value + 1;
        } else if (data.response === "todayshearing") {
          hearing.value = hearing.value + 1;
        } else if (data.response === "Jury Check In") {
          jury.value = jury.value + 1;
        } else if (data.response === "Telepresence") {
          tele.value = tele.value + 1;
        } else if (data.module === "QnA") {
          ai.value = ai.value + 1;
        }

        commit(
          "setPieChartWFTotal",
          faq.value +
            form.value +
            map.value +
            hearing.value +
            jury.value +
            tele.value
        );
      });
    },
    createFFPieChart({ getters, commit }, usersessionList) {
      commit("resetPieChartFF");
      usersessionList.forEach((session) => {
        const data = session.sessionData;
        // { label: "Protection Order", value: 0 },
        // { label: "Guide and File", value: 0 },
        // { label: "File and Serve", value: 0 },
        // { label: "Portal", value: 0 },

        var po = getters.pieChartFF.find(
          (item) => item.label === "Protection Order"
        );
        var gandf = getters.pieChartFF.find(
          (item) => item.label === "Guide and File"
        );
        var fands = getters.pieChartFF.find(
          (item) => item.label === "File and Serve"
        );
        var portal = getters.pieChartFF.find((item) => item.label === "Portal");
        var ai = getters.pieChartFF.find((item) => item.label === "QnA");

        if (data.response === "Order of Protection") {
          po.value = po.value + 1;
        } else if (data.response === "Civil No Contact Order") {
          po.value = po.value + 1;
        } else if (data.response === "Stalking No Contact Order") {
          po.value = po.value + 1;
        } else if (data.response === "Guide and File") {
          gandf.value = gandf.value + 1;
        } else if (data.response === "File and Serve") {
          fands.value = fands.value + 1;
        } else if (data.response === "Portal") {
          portal.value = portal.value + 1;
        } else if (data.module === "QnA") {
          ai.value = ai.value + 1;
        }

        commit(
          "setPieChartFFTotal",
          po.value + gandf.value + fands.value + portal.value + ai.value
        );
      });
    },
    createPOPieChart({ getters, commit }, usersessionList) {
      commit("resetPieChartPO");
      usersessionList.forEach((session) => {
        const data = session.sessionData;
        var po = getters.pieChartPO.find(
          (item) => item.label === "Order of Protection"
        );
        var cnco = getters.pieChartPO.find(
          (item) => item.label === "Civil No Contact Order"
        );
        var snco = getters.pieChartPO.find(
          (item) => item.label === "Stalking No Contact Order"
        );
        var ai = getters.pieChartPO.find((item) => item.label === "QnA");

        if (data.response === "Order of Protection") {
          po.value = po.value + 1;
        } else if (data.response === "Civil No Contact Order") {
          cnco.value = cnco.value + 1;
        } else if (data.response === "Stalking No Contact Order") {
          snco.value = snco.value + 1;
        } else if (data.module === "QnA") {
          ai.value = ai.value + 1;
        }

        commit("setPieChartPOTotal", po.value + cnco.value + snco.value);
      });
    },
    createEFPieChart({ getters, commit }, usersessionList) {
      commit("resetPieChartEF");
      usersessionList.forEach((session) => {
        const data = session.sessionData;
        var gandf = getters.pieChartEF.find(
          (item) => item.label === "Guide and File"
        );
        var fands = getters.pieChartEF.find(
          (item) => item.label === "File and Serve"
        );
        var portal = getters.pieChartEF.find((item) => item.label === "Portal");
        var ai = getters.pieChartEF.find((item) => item.label === "QnA");

        if (data.response === "Guide and File") {
          gandf.value = gandf.value + 1;
        } else if (data.response === "File and Serve") {
          fands.value = fands.value + 1;
        } else if (data.response === "Portal") {
          portal.value = portal.value + 1;
        } else if (data.module === "QnA") {
          ai.value = ai.value + 1;
        }

        commit(
          "setPieChartEFTotal",
          gandf.value + fands.value + portal.value + ai.value
        );
      });
    },
  },
};
